import React, {useEffect, useState} from 'react'
import {
    Box, InputAdornment,
    Stack,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TablePagination,
    TableRow,
    TableSortLabel,
    TextField,
    Typography,
    CircularProgress
} from "@mui/material";
import {visuallyHidden} from '@mui/utils';
import {useUsers} from "../../queries/Users/useUsers";
import ImportUsers from "./components/ImportUsers/ImportUsers";
import CreateUser from "./components/CreateUser/CreateUser";
import EditIcon from '@mui/icons-material/Edit';
import LockPersonIcon from '@mui/icons-material/LockPerson';
import SearchIcon from '@mui/icons-material/Search';
import LockOpenIcon from '@mui/icons-material/LockOpen';
import EditUser from "./components/EditUser/EditUser";
import DisableModal from "./components/DisableModal/DisableModal";
import Colors from "../../assets/Colors";

type Order = 'asc' | 'desc'

const sortableFields = ['givenName', 'familyName', 'email', 'userType'] as const
type SortKey = typeof sortableFields[number]

function descendingComparator<T extends Pick<User, SortKey>>(a: T, b: T, orderBy: SortKey) {
    if (b[orderBy]?.toLowerCase() < a[orderBy]?.toLowerCase()) {
        return -1;
    }
    if (b[orderBy]?.toLowerCase() > a[orderBy]?.toLowerCase()) {
        return 1;
    }
    return 0;
}

function getComparator(
    order: Order,
    orderBy: SortKey
): (
    a: Pick<User, SortKey>,
    b: Pick<User, SortKey>,
) => number {
    return order === 'desc'
        ? (a, b) => descendingComparator(a, b, orderBy)
        : (a, b) => -descendingComparator(a, b, orderBy);
}

export type User = {
    username: string
    defaultStoreNumber: string
    email: string
    givenName: string
    familyName: string
    userType: 'driver' | 'admin' | 'owner'| 'district_manager'
    enabled: boolean
    storeList: string[]
    phoneNumber?: string
    createdDate?: string
}

type HeadCell = {
    id: keyof User | 'edit' | 'disable',
    label?: string
}


const headCells: readonly HeadCell[] = [
    {
        id: 'username',
        label: 'USERNAME'
    },
    {
        id: 'givenName',
        label: 'FIRST NAME',
    },
    {
        id: 'familyName',
        label: 'LAST NAME'
    },
    {
        id: 'email',
        label: 'EMAIL'
    },
    {
        id: 'phoneNumber',
        label: 'PHONE'
    },
    {
        id: 'storeList',
        label: 'STORES(S)'
    },
    {
        id: 'userType',
        label: 'TYPE'
    },
    {
        id: 'createdDate',
        label: 'CREATED DATE'
    },
    {
        id: 'edit'
    },
    {
        id: 'disable'
    }
]

function UserManagement() {
    const [order, setOrder] = useState<Order>('asc');
    const [orderBy, setOrderBy] = useState<SortKey>('givenName');
    const [page, setPage] = useState<number>(0);
    const [rowsPerPage, setRowsPerPage] = useState<number>(10);
    const [searchBy, setSearchBy] = useState<string>('')
    const [users, setUsers] = useState<User[]>([])
    const [editUser, setEditUser] = useState<User | null>(null)
    const [disableUser, setDisableUser] = useState<User | null>(null)

    const { data, isLoading, isError } = useUsers()

    useEffect(() => {
        if (data) { setUsers(data) }
    }, [data])

    useEffect(() => {
        setPage(0)
        if (data) {
            if (searchBy) {
                const searchPattern = new RegExp(`^${searchBy}`, 'i');
                const phoneSearchPattern = new RegExp(searchBy, 'i')
                setUsers(data.filter(user => {
                    return searchPattern.test(user.givenName) || searchPattern.test(user.familyName) || searchPattern.test(`${user.givenName} ${user.familyName}`) || searchPattern.test(user.email) || user.storeList?.some(store => searchPattern.test(store) || phoneSearchPattern.test(`${user.phoneNumber}`))
                }))
            } else {
                setUsers(data)
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [searchBy])

    function handleChangePage(event: unknown, newPage: number): void {
        setPage(newPage);
    }

    const convertCreatedDate = (createdDate: string | undefined) => {
        if(createdDate) {
            const created = new Date(createdDate)
            const formattedDate = created.toLocaleString()
            return formattedDate
        }
        return ''

    }

    function handleChangeRowsPerPage(event: React.ChangeEvent<HTMLInputElement>): void {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    }

    function handleRequestSort(property: SortKey): void {
        const isAsc = orderBy === property && order === 'asc';
        setOrder(isAsc ? 'desc' : 'asc');
        setOrderBy(property);
    }

    const createSortHandler = (property: SortKey) => (): void => {
        handleRequestSort(property);
    };

    if(isError) {return (<h4>Error retrieving users.</h4>)}

    if(isLoading) { return <CircularProgress /> }

    return <Stack>
        <Stack direction={'row'} justifyContent={'space-between'} padding={2}>
            <Typography variant={'h4'} color={Colors.napaBlue}>User Management</Typography>
            <Stack direction={'row'} spacing={2}>
                <ImportUsers/>
                <CreateUser/>
            </Stack>
        </Stack>
        <Stack direction={'row'} justifyContent={'space-between'} padding={2}>
            <TextField id="standard-basic" placeholder='Search by name, email or phone number' variant="standard"
                       value={searchBy} sx={{minWidth: 350, width: 800}} InputProps={{
                startAdornment: (
                    <InputAdornment position="start">
                        <SearchIcon />
                    </InputAdornment>
                ),
            }}onChange={(e) => setSearchBy(e.currentTarget.value)}/>
            <TablePagination
                rowsPerPageOptions={[10, 20, 30]}
                component="div"
                count={users.length}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
            />
        </Stack>
        <TableContainer>
            <Table>
                <TableHead>
                    <TableRow>
                        {headCells.map(headCell => {
                            return <TableCell
                                key={headCell.id}
                                sortDirection={orderBy === headCell.id ? order : false}
                            >
                                {
                                    sortableFields.some(x => x === headCell.id) ?
                                    <TableSortLabel
                                        active={orderBy === headCell.id}
                                        direction={orderBy === headCell.id ? order : 'asc'}
                                        onClick={createSortHandler(headCell.id as SortKey)}
                                    >
                                        {orderBy === headCell.id ? (
                                            <>
                                            <Typography color={Colors.napaBlue} fontWeight={'bold'} variant={'body1'}>{headCell.label}</Typography>
                                            <Box component="span" sx={visuallyHidden}>
                                                {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                                            </Box>
                                            </>
                                        ) : <Typography color={Colors.napaGrey} variant={'body1'}>{headCell.label}</Typography>}
                                    </TableSortLabel> : <Typography color={Colors.napaGrey} variant={'body1'}>{headCell.label}</Typography>}
                            </TableCell>
                        })}
                    </TableRow>
                </TableHead>
                <TableBody>
                    {users.slice().sort(getComparator(order, orderBy))
                        .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                        .map(user => {
                            return <TableRow sx={{
                                '&.MuiTableRow-root:hover':{
                                    backgroundColor: '#f7f8ff'
                                },
                                '&:nth-of-type(odd)': {
                                    backgroundColor: Colors.napaGrey1,
                                },
                            }} key={Math.random()}>
                                <TableCell>{user.username}</TableCell>
                                <TableCell>{user.givenName}</TableCell>
                                <TableCell>{user.familyName}</TableCell>
                                <TableCell>{user.email}</TableCell>
                                <TableCell>{user.phoneNumber}</TableCell>
                                <TableCell>{user.storeList?.reduce((acc, storeNumber) => `${acc}#${storeNumber}, `, '').slice(0, -2)}</TableCell>
                                <TableCell>{user.userType}</TableCell>
                                <TableCell>{convertCreatedDate(user?.createdDate)}</TableCell>
                                <TableCell><EditIcon onClick={() => setEditUser(user)}/></TableCell>
                                <TableCell>{user.enabled ? <LockPersonIcon onClick={() => setDisableUser(user)}/> :
                                    <LockOpenIcon onClick={() => setDisableUser(user)}/>}</TableCell>
                            </TableRow>
                        })}
                </TableBody>
            </Table>
        </TableContainer>
        {editUser ? <EditUser user={editUser} setUser={setEditUser} /> : null}
        {disableUser ? <DisableModal user={disableUser} setUser={setDisableUser} /> : null}
    </Stack>
}

export default UserManagement
