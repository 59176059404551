import { CircularProgress } from "@mui/material";
import { useContext } from "react";
import NavigationBar from "../NavigationBar/NavigationBar";
import NavBarMenu from "./NavBarMenu";
import { AuthContext } from "../../hooks/AuthContext";
import Banner from "../Banner/Banner";
import { getAuthCode } from "../../services/PingAuthService/PingAuthService";

function ApacNavigator() {
  const { currentUser, isInitializingCurrentUser } = useContext(AuthContext);

  if (isInitializingCurrentUser) {
    return (
      <div className="Loading-container">
        <CircularProgress />
      </div>
    )
  }

  if (currentUser) {
    return (
      <>
        <Banner />
        <NavigationBar />
        <NavBarMenu />
      </>
    );
  } else {
    getAuthCode();
    return null;
  }
}
export default ApacNavigator;
