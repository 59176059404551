import {
  createContext,
  ReactElement,
  useContext,
  useEffect,
  useState,
} from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { AuthHelper } from "../helpers/AuthHelper";
import { SnackbarContext } from "./SnackbarContext";
import { isApac } from "../helpers/RegionHelper";
import { editDriver } from "../services/UserManagementService";
import {
  getUserDetailsByUserName,
  UserDataTypes,
} from "../services/UserService/UserService";
import {
  getAccessTokenWithRefreshToken,
  getPingTokenByCode,
} from "../services/PingAuthService/PingAuthService";
import {
  bufferTime,
  calculateExpireTime,
  getCookie,
  getCookieBoolean,
  removeCookie,
  secondToUnix,
  setCookie,
  unixToMiliSec,
} from "../helpers/CookieHelper";

export const AuthContext = createContext<AuthContextType>({
  currentUser: null,
  isInitializingCurrentUser: true,
  signIn: () => {},
  signOut: () => {},
});

type AuthContextType = {
  currentUser: CurrentUser | null;
  isInitializingCurrentUser: boolean;
  signIn: (username: string, password: string) => void;
  signOut: () => void;
};

export interface CurrentUser {
  id: string;
  email: string;
  groups?: string[];
}

type AuthProviderProps = {
  children: ReactElement;
};

export function AuthProvider({ children }: AuthProviderProps): ReactElement {
  let navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const [currentUser, setCurrentUser] = useState<CurrentUser | null>(null);
  const [isInitializingCurrentUser, setIsInitializingCurrentUser] =
    useState<boolean>(true);
  const { addSnack } = useContext(SnackbarContext);
  const tokenExpiry = unixToMiliSec(
    Number(getCookie("expires_in")) - bufferTime
  );
  const [expireTime, setExpireTime] = useState<number>(tokenExpiry);
  const [refreshTokenLoading, setRefreshTokenLoading] =
    useState<boolean>(false);
  const [refreshTokenExpiry, setRefreshTokenExpiry] = useState<number>();
  const isAuthenticated = getCookieBoolean("authenticated");

  // Compare user details with ping and update the database accordingly
  const fetchAndCompareUserData = async () => {
    try {
      const pingUserData = await AuthHelper.currentSignedInPingUser();
      // Refresh token expiry time setting from user-info call
      const expiryRefreshToken = unixToMiliSec(
        pingUserData.refreshTokenExpiryTime
      );
      setRefreshTokenExpiry(expiryRefreshToken);

      try {
        const userFromDB: UserDataTypes = await getUserDetailsByUserName(
          pingUserData.username
        );

        const currentUser = {
          id: pingUserData.username,
          email: pingUserData.UserPrincipalName,
          groups: pingUserData.groups,
        };

        setCookie("authenticated", "true");
        setCurrentUser(currentUser);

        // It will call the user update endpoint if data mismatched with ping
        const { payload, isUpdate } = AuthHelper.comparePingAndDBUser(
          pingUserData,
          userFromDB
        );
        if (isUpdate) {
          try {
            await editDriver(pingUserData.username, payload);
          } catch (e) {
            console.error("Unable to update user", e);
          }
        }
      } catch (error: any) {
        addSnack({
          severity: "error",
          message:
            error.response.status === 404
              ? "Please contact store admin to get added to the store."
              : error.response.data.message,
          duration: 3000,
        });
      }
    } catch (e: any) {
      addSnack({
        severity: "error",
        message: e.message,
        duration: 3000,
      });
    } finally {
      setIsInitializingCurrentUser(false);
    }
  };

  // To fetch the ping token which will be added to the cookie from server
  const getPingToken = async (code: string) => {
    try {
      const response = await getPingTokenByCode(code);
      if (response.success === "true") {
        fetchAndCompareUserData();
        const time = calculateExpireTime(response.expires_in);
        setExpireTime(time);
        // Here we are storing AccessToken expiry time in cookie
        setCookie("expires_in", secondToUnix(response.expires_in));
      }
    } catch (e) {
      addSnack({
        severity: "error",
        message: `Unable to get the token. ${e}`,
        duration: 3000,
      });
    }
  };

  const refreshTheToken = async () => {
    setRefreshTokenLoading(true);
    try {
      const response = await getAccessTokenWithRefreshToken();
      if (response.success === "true") {
        const time = calculateExpireTime(response.expires_in);
        setExpireTime(time);
        setCookie("expires_in", secondToUnix(response.expires_in));
      }
    } catch (e) {
      addSnack({
        severity: "error",
        message: `Unable to refresh the token. ${e}`,
        duration: 3000,
      });
    } finally {
      setRefreshTokenLoading(false);
    }
  };

  // get new access token using refresh token once it expired
  useEffect(() => {
    if (!refreshTokenLoading && expireTime && currentUser) {
      const timeoutId = setTimeout(() => {
        refreshTheToken();
      }, expireTime);
      return () => clearTimeout(timeoutId);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [refreshTokenLoading, expireTime, currentUser]);

  useEffect(() => {
    if (isApac) {
      const code = searchParams.get("code");
      if (isAuthenticated || code) {
        if (isAuthenticated) {
          fetchAndCompareUserData();
        } else if (code) {
          getPingToken(code);
        }
      } else {
        setIsInitializingCurrentUser(false);
      }
    } else {
      AuthHelper.currentSignedInUser()
        .then((user) => {
          setCurrentUser(user);
        })
        .catch((e) => console.error(e.message))
        .finally(() => setIsInitializingCurrentUser(false));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // logout the user after refresh token expiry
  useEffect(() => {
    if (refreshTokenExpiry) {
      const timeoutId = setTimeout(() => {
        setCurrentUser(null);
        removeCookie("authenticated");
        removeCookie("expires_in");
      }, refreshTokenExpiry);
      return () => clearTimeout(timeoutId);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [refreshTokenExpiry]);

  async function signIn(username: string, password: string): Promise<void> {
    try {
      const currentUser: CurrentUser | null = await AuthHelper.signIn({
        username,
        password,
      });
      setCurrentUser(currentUser);
      navigate("/");
    } catch (error) {
      return Promise.reject(error);
    }
  }

  async function signOut(): Promise<void> {
    if (isApac) {
      setIsInitializingCurrentUser(true);
      try {
        await AuthHelper.signOutPingUser();
        setCurrentUser(null);
        removeCookie("authenticated");
        removeCookie("expires_in");
      } catch (e: any) {
        addSnack({
          severity: "error",
          message: e.message,
          duration: 3000,
        });
      } finally {
        setIsInitializingCurrentUser(false);
      }
    } else {
      try {
        await AuthHelper.signOut();
      } catch (error: any) {
        console.error("Error signing out");
      } finally {
        setCurrentUser(null);
        navigate("/");
      }
    }
  }

  return (
    <AuthContext.Provider
      value={{
        currentUser,
        isInitializingCurrentUser,
        signOut,
        signIn,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
}
