import { Amplify } from "aws-amplify";
import { QueryClient, QueryClientProvider } from "react-query";
import { BrowserRouter } from "react-router-dom";
import { ThemeProvider } from "@mui/material";
import "./App.css";
import { AuthProvider } from "./hooks/AuthContext";
import { SnackbarProvider } from "./hooks/SnackbarContext";
import theme from "./hooks/theme";
import Navigator from "./components/Navigator/Navigator";
import { isApac } from "./helpers/RegionHelper";
import ApacNavigator from "./components/Navigator/ApacNavigator";

const queryClient = new QueryClient();

function App() {
  !isApac &&
    Amplify.configure({
      region: process.env.REACT_APP_COGNITO_REGION,
      userPoolId: process.env.REACT_APP_COGNITO_USER_POOL_ID,
      userPoolWebClientId:
        process.env.REACT_APP_COGNITO_USER_POOL_WEB_CLIENT_ID,
      identityPoolRegion: process.env.REACT_APP_COGNITO_USER_POOL_ID,
    });

  return (
    <div className="App">
      <ThemeProvider theme={theme}>
        <BrowserRouter>
          <QueryClientProvider client={queryClient}>
            <SnackbarProvider>
              <AuthProvider>
                {isApac ? <ApacNavigator /> : <Navigator />}
              </AuthProvider>
            </SnackbarProvider>
          </QueryClientProvider>
        </BrowserRouter>
      </ThemeProvider>
    </div>
  );
}

export default App;
