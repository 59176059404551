import * as React from 'react'
import { useContext, useState } from 'react'
import TextField from '@mui/material/TextField'
import { Button, Checkbox, IconButton, InputAdornment, Link } from '@mui/material'
import Visibility from '@mui/icons-material/Visibility'
import VisibilityOff from '@mui/icons-material/VisibilityOff'
import { useNavigate } from 'react-router-dom'
import logo from '../../../assets/logo.png'
import { Divider } from '@mui/material'
import { signInStyles } from './SignInStyles'
import {AuthContext} from "../../../hooks/AuthContext";
import {SnackbarContext} from "../../../hooks/SnackbarContext";
import {LoadingButton} from "@mui/lab";

function SignIn() {
    const [email, setEmail] = useState<string>('')
    const [password, setPassword] = useState<string>('')
    const [showDisabled, setShowDisabled] = useState<boolean>(true)
    const [showPassword, setShowPassword] = useState<boolean>(false)
    const [loading, setLoading] = useState<boolean>(false)
    const [emailErrorMessage, setEmailErrorMessage] = useState<string>('')
    const [passwordErrorMessage, setPasswordErrorMessage] = useState<string>('')
    const { signIn } = useContext(AuthContext)
    const { addSnack } = useContext(SnackbarContext)

    const navigate = useNavigate()

    function validateEmail(email: string): boolean {
        setEmail(email)
        /* eslint-disable */
        const emailRegex =
            /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i
        const validEmail = emailRegex.test(email.toLowerCase())
        if (validEmail) {
            setEmailErrorMessage('')
            setShowDisabled(false)
        } else {
            setEmailErrorMessage('Please enter a valid email address.')
            setShowDisabled(true)
            return false
        }
        return true
    }

    function validatePassword(password: string): boolean {
        setPassword(password)
        if (password) {
            setPasswordErrorMessage('')
            setShowDisabled(false)
        } else {
            setPasswordErrorMessage('Please enter a password')
            setShowDisabled(true)
            return false
        }
        return true
    }

    function handleLogin(): void {
        setLoading(true)
        try {
            signIn(email, password)
        } catch (error: any) {
            addSnack({
                severity: 'error',
                message: error.message,
                action: error.action ? { prompt: 'GOTO', callback: () => navigate(error.action) } : null,
                duration: 3000
            })
        } finally {
            setLoading(false)
        }
    }

    return (
        <div>
            <div style={signInStyles.main}>
                <img src={logo} alt='PROLink Logo' style={signInStyles.logo} />
                <h1 style={signInStyles.signInHeader}>Sign In</h1>

                <div style={signInStyles.emailTextField}>
                    <TextField
                        id="field-em"
                        style={signInStyles.fieldEmail}
                        inputProps={{ 'data-testid': 'email' }}
                        type="text"
                        label="Email*"
                        variant="outlined"
                        error={!!emailErrorMessage}
                        helperText={emailErrorMessage}
                        value={email}
                        onChange={(email) => validateEmail(email.target.value)}
                    />
                </div>
                <div style={signInStyles.passwordTextField}>
                    <TextField
                        id="field-pw"
                        style={signInStyles.fieldPassword}
                        inputProps={{ 'data-testid': 'password' }}
                        label="Password*"
                        variant="outlined"
                        value={password}
                        type={showPassword ? 'text' : 'password'}
                        error={!!passwordErrorMessage}
                        helperText={passwordErrorMessage}
                        onChange={(password) => validatePassword(password.target.value)}
                        InputProps={{
                            endAdornment: (
                                <InputAdornment position="end">
                                    <IconButton onClick={() => setShowPassword(!showPassword)}>
                                        {' '}
                                        {showPassword ? <Visibility /> : <VisibilityOff />}
                                    </IconButton>
                                </InputAdornment>
                            )
                        }}
                    />
                </div>
                <div style={signInStyles.checkboxContainer}>
                    <Checkbox inputProps={{ 'aria-label': 'controlled' }} style={signInStyles.checkbox} />
                    Keep me signed in.
                    <Link
                        href="#"
                        style={signInStyles.forgotPassword}
                        onClick={() => navigate('/ForgotPassword')}
                    >
                        {'Forgot your password?'}
                    </Link>
                </div>
                <div>
                    <LoadingButton
                        variant="primary"
                        data-testid="sign-in"
                        onClick={handleLogin}
                        disabled={showDisabled}
                        loading={loading}
                    >
                        Sign In
                    </LoadingButton>
                </div>
                <div style={signInStyles.newUserLabel}>
                    <Divider style={signInStyles.newUserLabel}> New To PROTracker</Divider>
                </div>
                <div>
                    <Button
                        variant="secondary"
                        data-testid="registration-btn"
                        onClick={() => navigate('/Registration')}
                    >
                        Complete Registration
                    </Button>
                </div>
            </div>
        </div>
    )
}

export default SignIn
