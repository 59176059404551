import axiosInstance from "../helpers/AxiosHelper";

const STORE_ENDPOINT = "/store";
type AddStoreRequest = {
  storeNumber: string;
  userId: string;
  userType: string;
};

export async function getStores(): Promise<any[]> {
  try {
    const response = await axiosInstance.get(`${STORE_ENDPOINT}`);
    return response.data;
  } catch (e) {
    return Promise.reject(e);
  }
}

export async function addStore(request: AddStoreRequest): Promise<any[]> {
  try {
    const response = await axiosInstance.post(`${STORE_ENDPOINT}`, request);
    return response.data;
  } catch (e) {
    return Promise.reject(e);
  }
}
