import axios, { AxiosError } from 'axios'
import { AuthHelper } from './AuthHelper'
import { removeCookie } from './CookieHelper'
import { isApac } from './RegionHelper'


const axiosInstance = axios.create({
  baseURL: process.env.NODE_ENV === 'test' ? undefined : process.env.REACT_APP_DELIVERYAPI_URL,
  ...((isApac) && { withCredentials: true })
})

// Add a request interceptor to handle token insertion
axiosInstance.interceptors.request.use(
  async (config) => {
    if (isApac) return config

    // Wait for the token to be fetched asynchronously for usag
    const token = (await AuthHelper.getUserAuthTokens()).accessToken
    
    // Set the Authorization header before sending the request for usag
    config.headers = config.headers ?? {};
    if (token) {
      config.headers['Authorization'] = `Bearer ${token}`
    }

    return config
  },
  (error) => {
    return Promise.reject(error)
  }
)

// Response interceptor
axiosInstance.interceptors.response.use(
  (response) => response,
  (error: AxiosError) => {
    // If unauthorized return to login screen
    if (error.response?.status === 401) {
      if (isApac){
        removeCookie('authenticated')
        removeCookie('expires_in')
      }
      return Promise.reject(error)
    }
    return Promise.reject(error)
  }
)

export default axiosInstance
