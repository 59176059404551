import { Auth } from "aws-amplify";
import { CurrentUser } from "../hooks/AuthContext";
import { CognitoUserSession } from "amazon-cognito-identity-js";
import {
  getPingUserData,
  pingUserDataTypes,
  signOutPing,
} from "../services/PingAuthService/PingAuthService";
import { UserDataTypes } from "../services/UserService/UserService";

type SignInRequest = {
  username: string;
  password: string;
};

export type UserAuthTokens = {
  accessToken: string;
  idToken: string;
  refreshToken: string;
};

export const isEmptyStr = (str: string | null | undefined): boolean =>
  !str || str.trim() === "";

export const isApacAccess = (userData: any): boolean =>
  userData &&
  userData.groups &&
  userData.groups.includes(process.env.REACT_APP_SUPERUSER);

export const getApacUserType = (userData: any): string => {
  if (userData.groups.includes(process.env.REACT_APP_SUPERUSER)) {
    return "superuser";
  } else {
    return "";
  }
};

//AWS Types suck, extend this as necessary
export type CognitoUserType = {
  attributes: CognitoUserAttributes;
  challengeName?:
    | "SMS_MFA"
    | "SOFTWARE_TOKEN_MFA"
    | "SELECT_MFA_TYPE"
    | "MFA_SETUP"
    | "PASSWORD_VERIFIER"
    | "CUSTOM_CHALLENGE"
    | "DEVICE_SRP_AUTH"
    | "DEVICE_PASSWORD_VERIFIER"
    | "ADMIN_NO_SRP_AUTH"
    | "NEW_PASSWORD_REQUIRED";
};

type CognitoUserAttributes = Record<CognitoUserAttributeKey, string>;
type CognitoUserAttributeKey = "email" | "sub";

export class AuthHelper {
  static async signIn(signInRequest: SignInRequest): Promise<CurrentUser> {
    try {
      //TODO: type responses when we switch to GCP
      const cognitoUser: CognitoUserType = await Auth.signIn({
        username: signInRequest.username,
        password: signInRequest.password,
      });

      if (cognitoUser.challengeName === "NEW_PASSWORD_REQUIRED") {
        return Promise.reject({
          message: cognitoUser.challengeName,
          user: cognitoUser,
        });
      }

      const currentUser = {
        id: cognitoUser.attributes.sub,
        email: cognitoUser.attributes.email,
      };

      return currentUser;
    } catch (error: any) {
      switch (error.message) {
        case "Incorrect username or password.":
          return Promise.reject({
            message:
              "Incorrect username and/or password. Try again or Reset your password",
            action: "/ResetPassword",
          });
        case "User is disabled.":
          return Promise.reject({
            message:
              "This account has been disabled. Please contact terrapin@genpt.com or something",
          });
        case "Password attempts exceeded":
          return Promise.reject({
            message:
              "Please try again later or click here to reset your password",
            action: "/ForgotPassword",
          });
        case "New password required":
          return Promise.reject({
            reason: "NEW_PASSWORD_REQUIRED",
            user: error.user,
          });
        default:
          return Promise.reject({ message: "Unknown Service Error" });
      }
    }
  }

  static async signOut() {
    try {
      await Auth.signOut();
    } catch (error: any) {
      console.error("Error signing out of cognito");
    }
  }

  static async completePasswordChallenge(
    user: CognitoUserType,
    newPassword: string
  ) {
    try {
      await Auth.completeNewPassword(user, newPassword);
    } catch (error) {
      return Promise.reject(error);
    }
  }

  static async currentSignedInPingUser(): Promise<pingUserDataTypes> {
    try {
      const apacUser: pingUserDataTypes = await getPingUserData();

      if (!isApacAccess(apacUser))
        return Promise.reject({
          message: `${apacUser.username} does not have sufficient permissions; please contact your administrator`,
        });

      return apacUser;
    } catch (e) {
      return Promise.reject({
        message: "No user has been Authenticated",
      });
    }
  }

  static comparePingAndDBUser(
    pingUserData: pingUserDataTypes,
    dbData: UserDataTypes
  ) {
    let payload: any = {};
    let isUpdate: boolean = false;

    const {
      given_name,
      family_name,
      UserPrincipalName,
      phone_number,
      groups,
      username: pingUserName,
    } = pingUserData;
    const {
      givenName,
      familyName,
      email,
      phoneNumber,
      userType,
      defaultStoreNumber,
      storeList,
      username,
    } = dbData;

    const compareData = (ping: string, db: string, key: string) => {
      if (!isEmptyStr(ping) && ping !== db) {
        payload = { ...payload, [key]: ping };
        isUpdate = true;
      } else {
        payload = { ...payload, [key]: db };
      }
    };

    const compareUserType = () => {
      if (groups.length && getApacUserType(pingUserData) !== userType) {
        payload = { ...payload, userType: getApacUserType(pingUserData) };
        isUpdate = true;
      } else {
        payload = { ...payload, userType };
      }
    };

    compareData(given_name, givenName, "givenName");
    compareData(family_name, familyName, "familyName");
    compareData(UserPrincipalName, email, "email");
    compareData(phone_number, phoneNumber, "phoneNumber");
    compareData(pingUserName, username, "username");
    compareUserType();
    payload = { ...payload, defaultStoreNumber, storeList };

    return { payload, isUpdate };
  }

  static async currentSignedInUser(): Promise<CurrentUser> {
    try {
      const cognitoUser: CognitoUserType =
        await Auth.currentAuthenticatedUser();
      return {
        id: cognitoUser.attributes.sub,
        email: cognitoUser.attributes.email,
      };
    } catch (e) {
      return Promise.reject({
        message: "No user has been Authenticated",
      });
    }
  }

  static async getUserAuthTokens(): Promise<UserAuthTokens> {
    try {
      const currentSession: CognitoUserSession = await Auth.currentSession();
      return {
        accessToken: currentSession.getAccessToken().getJwtToken(),
        idToken: currentSession.getIdToken().getJwtToken(),
        refreshToken: currentSession.getRefreshToken().getToken(),
      };
    } catch (error: any) {
      return Promise.reject(error.message);
    }
  }

  static async signOutPingUser() {
    try {
      await signOutPing();
    } catch (e) {
      Promise.reject({
        message: "Error signing out the user",
      });
    }
  }
}
