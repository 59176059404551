import Cookies from "js-cookie";

export const getCookie = (name: string) => {
  return Cookies.get(name);
};

export const getCookieBoolean = (name: string): boolean => {
  return Cookies.get(name) === "true";
};

export const setCookie = (name: string, value: string) => {
  Cookies.set(name, value, {
    sameSite: "None",
    secure: true,
    expires: 1,
    domain: ".genpt.com",
  });
};

export const removeCookie = (name: string) => {
  Cookies.remove(name, {
    sameSite: "None",
    secure: true,
    expires: 1,
    domain: ".genpt.com",
  });
};

// added buffer time to call the token before 5 minutes
export const bufferTime = 300;

export const calculateExpireTime = (time: string): number => {
  return (Number(time) - bufferTime) * 1000;
};

// Unix time stamp to mili second conversion
export const unixToMiliSec = (time: number): number => {
  const currentTime = Math.floor(Date.now() / 1000);
  return (time - currentTime) * 1000;
};

// Second to unix time stamp conversion
export const secondToUnix = (time: string): string => {
  const currentTime = Math.floor(Date.now() / 1000);
  return (Number(time) + currentTime).toString();
};
