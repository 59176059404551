import { User } from "../components/UserManagement/UserManagement";
import { UserType } from "../components/UserManagement/components/UserTypeSelect/UserTypeSelect";
import axiosInstance from "../helpers/AxiosHelper";

const USER_ENDPOINT = `/users/v2`;

export async function getUsers(): Promise<User[]> {
  try {
    const response = await axiosInstance.get(`${USER_ENDPOINT}`);
    return response.data.map((user: any) => ({
      username: user.username,
      email: user.email,
      givenName: user.givenName,
      familyName: user.familyName,
      defaultStoreNumber: user.defaultStoreNumber,
      userType: user.userType,
      enabled: user.enabled,
      storeList: user.storeList,
      phoneNumber: user.phoneNumber,
      createdDate: user.createdDate,
    }));
  } catch (e) {
    return Promise.reject(e);
  }
}

type AddUserRequest = {
  email: string;
  familyName: string;
  givenName: string;
  phoneNumber?: string;
  defaultStoreNumber: string;
  userType: string;
  storeList: string[];
  username?: string | null;
};

export async function addUser(request: AddUserRequest): Promise<void> {
  try {
    const response = await axiosInstance.post(
      `${USER_ENDPOINT}/adminCreate`,
      request
    );
    if (response.status === 200) {
      return response.data;
    }
    return Promise.reject(`${response.data.message}`);
  } catch (e) {
    return Promise.reject(e);
  }
}

export async function batchImportUsers(file: File): Promise<void> {
  //Convert File type to form data
  let formData = new FormData();
  formData.append("file", file);

  try {
    const response = await axiosInstance.post(
      `${USER_ENDPOINT}/batchAdminCreate`,
      formData
    );
    return response.data;
  } catch (e) {
    return Promise.reject(e);
  }
}

type EditDriverRequest = {
  email: string;
  givenName: string;
  familyName: string;
  phoneNumber?: string;
  defaultStoreNumber: string;
  storeList: string[];
  userType: UserType;
  username: string;
};

export async function editDriver(
  username: string,
  editDriverRequest: EditDriverRequest
): Promise<void> {
  try {
    const response = await axiosInstance.put(
      `${USER_ENDPOINT}/username/${username}`,
      editDriverRequest
    );
    if (response.status === 200) {
      return response.data;
    }
    return Promise.reject(`${response.data.message}`);
  } catch (e) {
    return Promise.reject(e);
  }
}

export async function disableUser(username: string): Promise<void> {
  try {
    await axiosInstance.delete(`${USER_ENDPOINT}/disable/${username}`);
  } catch (e) {
    return Promise.reject(e);
  }
}

export async function resendPW(username: string): Promise<void> {
  try {
    await axiosInstance.get(
      `${USER_ENDPOINT}/resendPassword?username=${username}`
    );
  } catch (e) {
    return Promise.reject(e);
  }
}

export async function enableUser(username: string): Promise<void> {
  try {
    await axiosInstance.post(`${USER_ENDPOINT}/enable/${username}`);
  } catch (e) {
    return Promise.reject(e);
  }
}
